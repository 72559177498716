<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Title</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(department, index) in cit_milestones.data"
                  :key="index"
                  v-if="cit_milestones.data.length"
                >
                  <td class="align-middle text-center">
                    {{ cit_milestones.from + index }}
                  </td>
                  <td>
                    <p class="mb-0">
                      <b>
                        {{ department.title }}
                      </b>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        department.status == 1
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ department.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(department.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing
                  {{ cit_milestones.from ? cit_milestones.from : 0 }} to
                  {{ cit_milestones.to ? cit_milestones.to : 0 }} of
                  {{ cit_milestones.total ? cit_milestones.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="cit_milestones"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="(form.id == '' ? 'Create' : 'Update') + ' Cit Milestone'"
        >
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Title" label-for="title">
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-form-input
                          id="title"
                          v-model="form.title"
                          :state="errors.length > 0 ? false : null"
                          name="title"
                          placeholder="Enter Your Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Description" label-for="description">
                      <validation-provider
                        #default="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="form.description"
                          :state="errors.length > 0 ? false : null"
                          name="description"
                          placeholder="Enter Your Description"
                          rows="6"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-media no-body vertical-align="center">
                        <b-media-aside>
                          <b-img
                            :src="image"
                            height="174"
                            width="244"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                      </b-media>
                      <b-media-body class="mt-2">
                        <b-form-file
                          id="image"
                          accept=".jpg, .png, .gif"
                          @change="loadImage($event)"
                          placeholder="Select Image"
                          drop-placeholder="Drop file here..."
                        />
                      </b-media-body>
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        title: "",
        description: "",
        status: true,
        image: "",
      },
      image: this.$store.state.base_url + "images/cit_milestone/default.png",
      cit_milestones: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const cit_milestones = await this.callApi(
        "post",
        "/app/cit/milestone?page=" + this.search.page,
        this.search
      );
      if (cit_milestones.status == 200) {
        this.cit_milestones = cit_milestones.data.cit_milestones;
      }
    },

    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.title = "";
      this.form.description = "";
      this.form.status = true;
      this.form.image = "";
      this.image =
        this.$store.state.base_url + "images/cit_milestone/default.png";
    },

    async edit(id) {
      const cit_milestone = await this.callApi(
        "post",
        "/app/cit/milestone/show/" + id
      );
      if (cit_milestone.status == 200) {
        this.form.id = cit_milestone.data.cit_milestone.id;
        this.form.title = cit_milestone.data.cit_milestone.title;
        this.form.description = cit_milestone.data.cit_milestone.description;
        this.form.status =
          cit_milestone.data.cit_milestone.status == true ? true : false;
        this.image =
          this.$store.state.base_url + cit_milestone.data.cit_milestone.image;
      }
    },

    add() {
      const fd = new FormData();
      if (this.form.image) {
        fd.append("image", this.form.image, this.form.image.name);
      }

      fd.append("id", this.form.id);
      fd.append("title", this.form.title);
      fd.append("description", this.form.description);
      fd.append("status", this.form.status);

      axios
        .post("/app/cit/milestone/add", fd)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "cit.milestone.access") {
          this.permission.access = true;
        }

        if (permission.slug == "cit.milestone.create") {
          this.permission.create = true;
        }

        if (permission.slug == "cit.milestone.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "cit.milestone.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  computed: {},
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
